html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
}

.MuiTableCell-head .MuiTypography-body1 {
  font-weight: 500;
  line-height: 1.5rem;
  font-size: 0.875rem;
}
